import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import { ISearchResult } from '../../pages/help/search'
import CommonH3 from '../atoms/commonH3'
import TopicPath from '../atoms/topicPath'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 20px 30px 0;
  margin: 20px auto 30px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  h3 {
    span {
      font-size: 1.6em;
      border: none;

      &:before {
        display: none;
      }
    }
  }
`
type IProps = ISearchResult

/**
 * 検索カード
 * @constructor
 */
const SearchCard = ({ helpId, title, body, paths }: IProps) => {
  const link = `/help/article/${helpId}`
  return (
    <Wrapper>
      <Link to={useRouteParam(link)}>
        <CommonH3 label={title} />
      </Link>
      <p>{body}</p>
      <TopicPath paths={paths} />
    </Wrapper>
  )
}

export default SearchCard
