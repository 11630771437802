import styled from '@emotion/styled'
import { graphql, navigate, PageProps } from 'gatsby'
import React, { useEffect, useMemo, useState } from 'react'
import CommonH2 from '../../components/atoms/commonH2'
import CommonInput from '../../components/atoms/commonInput'
import SEO from '../../components/atoms/seo'
import { ITopicPath } from '../../components/atoms/topicPath'
import Pagination from '../../components/molecules/pagination'
import SearchCard from '../../components/molecules/searchCard'
import Layout from '../../components/organisms/layout'
import useHelpData from '../../hooks/useHelpData'
import useRouteParam from '../../hooks/useRouteParam'
import BreakPoints from '../../styles/breakPoints'

const Section = styled.section`
  .search-wrap {
    max-width: 480px;
    min-width: 280px;
    margin: 30px auto 15px;
    position: relative;

    .svg-icon {
      z-index: 1;
      top: 10px;
      right: 5px;
      width: 28px;
      position: absolute;
    }

    .search-input {
      width: 100%;

      input {
        background: #ffffff;
        height: 50px;
        padding: 5px 10px;
      }
    }
  }

  .search-input {
    width: 400px;

    input {
      background: #ffffff;
      margin-bottom: 20px;
    }
  }

  .get-word {
    text-align: center;
  }

  ${BreakPoints.xLarge} {
    .get-word {
      padding: 0 20px;
    }
  }
`
const Button = styled.button`
  display: inline-flex;
  height: 50px;
  width: 80px;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  border-radius: 0 8px 8px 0;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #19ba9b;
  border: #19ba9b solid 2px;
  border-left: none;
  overflow: hidden;
  position: absolute;
  right: 0;
`

export type ISearchResult = {
  /** ヘルプID */
  helpId: string
  /** タイトル */
  title: string
  /** 本文 */
  body: string
  /** スラッグ */
  slug: string
  /** パンくず */
  paths: ITopicPath[]
}

type IProps = PageProps<GatsbyTypes.SearchPageQuery>

/** 検索で表示する最大文字数 */
const MAX_BODY_LENGTH = 128
/** 検索実行までの待ち時間(ms) */
const SEARCH_WAIT = 500
/** 1ページでの表示件数 */
const PAGE_COUNT = 20
/**
 * 検索ページ
 * @constructor
 */
const SearchPage = ({ data, location }: IProps) => {
  const [word, setWord] = useState('')
  const [page, setPage] = useState(0)
  const { helpCategoryMap } = useHelpData()

  const [results, setResults] = useState<ISearchResult[]>([])
  const searchParam = new URLSearchParams(location.search)
  // GraphQLで取得したデータを保持
  const helpDetails = data.awsGraphQL.listHelpDetails?.items || []

  // ヘルプIDがキーの詳細マップ情報を作成
  const searchDetails: ISearchResult[] = useMemo(() => {
    const _result: ISearchResult[] = []
    helpDetails.forEach((helpDetail) => {
      if (helpDetail?._deleted) return
      const _helpCategoryId = helpDetail?.helpCategoryId || ''
      const _helpId = helpDetail?.helpId || ''
      if (helpCategoryMap[_helpCategoryId]) {
        const category = helpCategoryMap[_helpCategoryId]
        _result.push({
          helpId: _helpId,
          title: helpDetail?.title || '',
          body: helpDetail?.body || '',
          slug: category.slug,
          paths: [
            { title: 'TOP', link: useRouteParam('/help') },
            {
              title: category.title,
              link: useRouteParam(`/help/${category.slug}`),
            },
          ],
        })
      }
    })
    return _result
  }, [helpDetails])

  // 検索ワード切り替え時処理
  useEffect(() => {
    setWord(searchParam.get('word') || '')
  }, [location.search])

  useEffect(() => {
    const id = setTimeout(() => {
      // 全角空白を半角空白に置換し、半角空白で分割する
      const words = word
        .replace('　', ' ')
        .split(' ')
        .filter((val) => !!val)
      // 検索が設定されていなければ無視
      if (words.length === 0) return

      // 一致している詳細を検索
      const _results = searchDetails
        .filter((details) => {
          let isFind = true
          words.forEach((_word) => {
            if (
              details.title.indexOf(_word) === -1 &&
              details.body.indexOf(_word) === -1
            )
              isFind = false
          })
          return isFind
        })
        .map((val) => ({
          ...val,
          body: `${val.body
            .replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '')
            .replace(/#/g, '')
            .substr(0, MAX_BODY_LENGTH)}...`,
        }))
      setPage(0)
      setResults(_results)
      navigate(`/help/search?word=${word}`)
    }, SEARCH_WAIT)
    return () => {
      clearTimeout(id)
    }
  }, [word])

  return (
    <Layout location={location}>
      <SEO title="検索結果" />
      <Section>
        <div className="search-wrap">
          <CommonInput
            value={word}
            onChangeValue={setWord}
            className="search-input"
            placeholder="検索キーワードを入力"
          />
          <Button className="search-btn">検索</Button>
        </div>
        <CommonH2 className="mb-030" label="検索結果" />
        <p className="get-word">
          "{searchParam.get('word')}" の検索結果 {results.length}件
        </p>
        {[...results].splice(page * PAGE_COUNT, PAGE_COUNT).map((result) => (
          <SearchCard key={`result-${result.helpId}`} {...result} />
        ))}
        <Pagination
          count={Math.ceil(results.length / PAGE_COUNT)}
          page={page}
          onChange={setPage}
        />
      </Section>
    </Layout>
  )
}

export default SearchPage

export const pageQuery = graphql`
  query SearchPage {
    awsGraphQL {
      listHelpDetails {
        items {
          title
          body
          sortNo
          helpId
          helpCategoryId
          _deleted
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
