import React, { useEffect } from 'react'
import styled from '@emotion/styled'

const Ul = styled.ul`
  margin: 0;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;

  button {
    display: inline-flex;
    height: 26px;
    min-width: 26px;
    font-size: 0.875rem;
    position: relative;

    background: rgba(216, 216, 216, 0.5);
    border-radius: 13px;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    margin: 0 2px;

    &:disabled {
      color: rgb(255, 255, 255) !important;
      opacity: 0.38;
      cursor: default;
      pointer-events: none;
      border-color: hsla(0, 0%, 86.3%, 0.2);
    }
  }
`
type IProps = {
  /** 総ページ数 */
  count: number
  /** 現在ページ番号 */
  page: number
  /** 変更時コールバック */
  onChange: (page: number) => void
}

/**
 * ページング用コンポーネント
 * @constructor
 */
const Pagination = ({ count, page, onChange }: IProps) => {
  useEffect(() => {
    if (count <= page) onChange(0)
  }, [count, page])

  const onClickPage = (_page: number) => () => {
    onChange(_page)
  }
  return (
    <Ul className="MuiPagination-ul">
      <li>
        <button
          className="MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-page Mui-disabled MuiPaginationItem-sizeSmall Mui-disabled"
          type="button"
          disabled={page === 0 || count === 0}
          onClick={onClickPage(page - 1)}
        >
          &lt;
        </button>
      </li>
      {[...Array(count)].map((_, index) => (
        <li key={`page-${index}`}>
          <button
            className="MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-page Mui-selected MuiPaginationItem-sizeSmall"
            type="button"
            disabled={index === page}
            onClick={onClickPage(index)}
          >
            {index + 1}
            <span className="MuiTouchRipple-root" />
          </button>
        </li>
      ))}
      <li>
        <button
          type="button"
          disabled={count <= page + 1 || count === 0}
          onClick={onClickPage(page + 1)}
        >
          &gt;
          <span className="MuiTouchRipple-root" />
        </button>
      </li>
    </Ul>
  )
}

export default Pagination
